@use '@angular/material' as mat;
@use '/src/datepicker-theme';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ng-select/ng-select/themes/ant.design.theme.css';
@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');

@include mat.core();
$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme($primary, $accent, $warn);
@include mat.all-component-themes($theme);
@include datepicker-theme.theme($theme);

html,
body {
  height: 100%;
}

.CodeMirror-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 9;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  width: 8px !important;
  height: 8px !important;
}

.ng-select .ng-clear-wrapper {
  position: absolute !important;
  width: 12px !important;
}

.ng-select .ng-clear-wrapper .ng-clear {
  font-size: 12px !important;
  line-height: 12px !important;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: #00d68f white;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 3px solid #00d68f;
}

.bg-logo {
  background: url(assets/img/logo.png) no-repeat left/contain;
  padding-left: 45px;
}

.flex-fill {
  flex: 1;
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
    no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

/* Filtering table demo */

ngbd-table-filtering span.ngb-highlight {
  background-color: yellow;
}

/* Complete table demo */

ngbd-table-complete span.ngb-highlight {
  background-color: yellow;
}

.ng-select.ng-select-single.custom .ng-select-container,
.ng-input {
  min-width: 250px;
}

.ng-select.ng-select-single.custom.compact .ng-select-container,
.ng-input {
  min-width: 150px;
}

.ng-select.ng-select-single.custom .ng-option {
  padding: 2px;
  font-size: 14px;
}

.card.drop-box {
  background: #f8f8f8;
  border: 2px dashed #ddd;
  text-align: center;
  padding: 20px 10px;
}

.card.drop-box.nv-file-over {
  border: 2px dashed blue;
}

#main .btn-primary {
  background: #64a70b;
}

#main .btn-primary:hover {
  background: #59940b;
}

#main .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #64a70b;
}

#main .nav-pills a {
  color: #64a70b;
  text-decoration: none;
  background-color: transparent;
}

::selection {
  background-color: #64a70b;
  color: #fff;
}

.green-clickable {
  color: #28a745;
}

.green-clickable:hover {
  color: #218838;
}

.red-clickable {
  color: #dc3545;
}

.red-clickable:hover {
  color: #c82333;
}

#main .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #64a70b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #59940b;
}

.above-overlay {
  z-index: 9999;
  position: relative;
  background-color: white;
  width: 100%;
}

.overlay {
  z-index: 100;
  background: #e9e9e9;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
}

.sp {
  z-index: 100;
  width: 42px;
  height: 42px;
  clear: both;
  position: absolute;
  left: calc(50% - 21px);
  top: 21px;
}

.sp-circle {
  border: 4px #bbb solid;
  border-top: 4px #64a70b solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear;
}

.sp-circle-btn {
  width: 1rem;
  height: 1rem;
  clear: both;
  border: 4px #ddd solid;
  border-top: 4px #64a70b solid;
  display: inline-block;
}

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.clickableTableHeader {
  cursor: pointer;
}

.search-control {
  width: 200px;
  margin-left: auto;
  margin-right: 20px;
}
